<template>
  <div id="openAccount">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('menu.openAdditAcc') }}</h2>
          <p></p>
        </div>
        <div class="account_shadow_box" v-if="!success">
          <p class="account_title">{{ $t('register.accountConfig.pageTitle') }}</p>
          <el-form :model="form" :rules="rules" ref="ruleForm">
            <el-row :gutter="60">
              <el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('openAdditAcc.chooseTradePlat') }}</label>
                </div>
                <el-form-item prop="platform">
                  <SelectForm v-model="form.platform" name="platform">
                    <el-option
                      v-for="(item, index) in choosePlatform"
                      :key="index"
                      :value="item.value"
                      :label="item.name"
                      :data-testid="item.value"
                    ></el-option>
                  </SelectForm>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('openAdditAcc.chooseAccType') }}</label>
                </div>
                <el-form-item prop="accountType">
                  <SelectForm v-model="form.accountType" name="accountType">
                    <el-option
                      v-for="(item, index) in chooseAccountType"
                      :key="index"
                      :value="item.value"
                      :label="item.label"
                      :data-testid="item.value"
                    ></el-option>
                  </SelectForm>
                </el-form-item>
                <div class="pamm_tips" v-show="form.accountType === 'PAMM'">{{ $t('openAdditAcc.pammTips') }}</div>
              </el-col>

              <el-col el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('openAdditAcc.chooseCurrency') }}</label>
                </div>
                <el-form-item prop="currency">
                  <SelectForm v-model="form.currency" name="currency">
                    <el-option
                      v-for="item in $config.openLiveAccount.chooseCurrency(regulator, $store.state.common.countryCode)"
                      :key="item.currency"
                      :value="item.currency"
                      :label="item.value"
                      :data-testid="item.currency"
                    ></el-option>
                  </SelectForm>
                </el-form-item>
              </el-col>

              <el-col el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <label for="">{{ $t('openAdditAcc.notes') }}</label>
                </div>
                <InputForm name="notes" v-model="form.notes" class="notes"></InputForm>
              </el-col>
            </el-row>

            <div class="tnc">
              <el-form-item prop="checkStatus" class="checkStatus">
                <el-checkbox
                  name="type"
                  :label="$t('openAdditAcc.byTicking')"
                  v-model="form.checkStatus"
                  data-testid="byTicking"
                ></el-checkbox>
              </el-form-item>

              <ul
                dir="ltr"
                v-html="
                  $t($config.openLiveAccount.getAgreeCondition(regulator), {
                    platform: $config.info.fullName,
                    platformEntityName: $config.info.entityName,
                    regulatorUrl: GLOBAL_DOMAIN_WEBSITE
                  })
                "
              ></ul>
            </div>

            <div class="btn_item">
              <el-button class="purple_button" @click="submitClick" data-testid="submit">{{
                $t('common.button.submit')
              }}</el-button>
            </div>
          </el-form>
        </div>
        <Result v-else>
          <div v-html="$t('openAdditAcc.succ')"></div>
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import Result from '@/components/Result';
import { apiApplyAdditionalAccount, apiGetAdditionalAccountType } from '@/resource';
import { checkAgreedTNC } from '@/util/validation';
export default {
  name: 'openLiveAccount',
  components: { Result },
  data() {
    return {
      form: {
        platform: 'mt4',
        accountType: null,
        currency: null,
        notes: '',
        checkStatus: false
      },
      rules: {
        platform: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('openAdditAcc.platErr')
          }
        ],
        accountType: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('openAdditAcc.accTypeErr')
          }
        ],
        currency: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('openAdditAcc.currError')
          }
        ],
        checkStatus: [
          {
            validator: checkAgreedTNC,
            trigger: 'change'
          }
        ]
      },
      success: false,
      accountTypeList: { mt4: [], mt5: [] }
    };
  },
  mounted() {
    this.getApiGetAdditionalAccountType();
  },
  computed: {
    choosePlatform() {
      return this.$config.openLiveAccount
        .choosePlatform(this.regulator)
        .filter(platform => this.accountTypeList[platform.value].length > 0);
    },
    chooseAccountType() {
      return this.accountTypeList[this.form.platform];
    }
  },
  methods: {
    getApiGetAdditionalAccountType() {
      apiGetAdditionalAccountType().then(resp => {
        const accountTypes = resp.data.data;
        const chooseLiveType = this.$config.openLiveAccount.chooseLiveType(this.regulator);

        this.accountTypeList = {
          mt4: chooseLiveType.filter(type => accountTypes.mt4.includes(type.value)),
          mt5: chooseLiveType.filter(type => accountTypes.mt5.includes(type.value))
        };
      });
    },
    submitClick() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) this.postApiApplyAdditionalAccount();
        else return false;
      });
    },
    postApiApplyAdditionalAccount() {
      let escapeString = this.escapeStringForJSON(this.form.notes);
      apiApplyAdditionalAccount({
        notes: escapeString,
        accountType: this.form.accountType,
        tradingPlatform: this.form.platform,
        currency: this.form.currency
      }).then(resp => {
        if (resp.data.code === 0) this.success = true;
      });
    },
    escapeStringForJSON(string) {
      return string.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/openAccount.scss';
</style>
